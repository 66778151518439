import * as React from "react"

function SlideArrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" {...props}>
      <path d="M37.78 131.94a6 6 0 01-3.69-10.74L97.43 72 34.09 22.8a6 6 0 117.36-9.47L109.28 66l.21.17a7.55 7.55 0 010 11.64l-.21.17-67.83 52.69a6 6 0 01-3.67 1.27z" />
    </svg>
  )
}

export default SlideArrow
import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import SlideArrow from "../assets/SlideArrow"
import SlideArrowLeft from "../assets/slideArrowLeft"



const SliderWrapper = styled.div`
  margin-bottom: 5.5rem;
  /* border: 5px solid aqua;
  background: azure; */
  max-width: 100%;
  height: 310px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  white-space: nowrap;
  animation-iteration-count: infinite;
  position: relative;
  @media (min-width: 1028px) {
    overflow: hidden;
    height: 410px;
  }
`

const SlideContainer = styled.div`
  position: absolute;
  left: ${props => props.xOffset};
  transition: all 0.25s ease-in-out;
  animation-iteration-count: infinite;
  display: flex;
  > div:not(:first-child) {
    margin-left: 1rem;
  }
`

const Slide = styled.div`
  margin: 1rem 0;
  height: 310px;
  background-color: ${props => props.color};
  display: flex;
  border: 1px solid #f1f2f2;
  border-radius: 14px;
  padding: 0.5rem;
  @media (min-width: 1028px) {
    height: 360px;
  }
  :hover {
    transform: scale(1.01);
    box-shadow: 1px 2px 4px lightgrey;
  }
`

const BtnContainer = styled.div``

const Button = styled.button`
  display: none;
  @media (min-width: 1280px) {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    left: ${props => props.left};
    right: ${props => props.right};
    border: none;
    border-radius: 100vw;
    box-shadow: 1px 1px 4px grey;
    background: #f7f7f7;
    display: ${props => props.width ? "inline-flex" : "none"};
    justify-content: center;
    align-items: center;
    padding: 1rem;
    :hover {
      background: #dfdfdf;
      box-shadow: none;
    }
  }
`

const CustomLink = styled(Link)`
  height: 100%;
`

const CustomImg = styled(Img)`
  width: 250px;
  border-radius: 1vw;
  @media (min-width: 1280px) {
    width: 300px;
  }
`

const Type = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: #111111;
  margin-left: 1rem;
`

const CustomSlider = ({ slideData }) => {
  console.log(slideData)
  const [xOffset, setXoffset] = useState("0px")
  const containerWidth = useRef()
  const [hidden, setHidden] = useState(false);

  const prevRef = useRef()
  const nextRef = useRef()

  const width = 0;

  const handleClick = () => {
    if (xOffset === "0px") {
      setXoffset("-597px")
    }
    if (xOffset === "-597px") {
      setXoffset("0px")
    }
  }

  useEffect(() => {
    if (xOffset === "-597px") {
      prevRef.current.disabled = false
      nextRef.current.disabled = true
    } else if (xOffset === "0px") {
      prevRef.current.disabled = true
      nextRef.current.disabled = false
    }
    if(containerWidth.current.offsetWidth >= 1440) {
        setHidden(true);
    }

    console.log(containerWidth.current.offsetWidth)
  }, [xOffset])

  return (
    <SliderWrapper>
      <SlideContainer ref={containerWidth} xOffset={xOffset}>
        {slideData && (
          <>
            {slideData.map(i => (
              <Slide>
                <Link to={i.link.slug}>
                  <CustomImg fluid={i.image.localFile.childImageSharp.fluid} />
                  <Type>{i.type}</Type>
                  <Type>{i.price}</Type>
                </Link>
              </Slide>
            ))}
          </>
        )}
      </SlideContainer>
      <Button  width={hidden} ref={prevRef} onClick={() => setXoffset("0px")} left={"10px"}>
        <SlideArrowLeft />
      </Button>
      <Button width={hidden}  ref={nextRef} onClick={handleClick} right={"10px"}>
      <SlideArrow />
      </Button>
    </SliderWrapper>
  )
}

export default CustomSlider

import React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import styled from 'styled-components'
import StuhlSwiper from "../components/StuhlSwiper"
import CustomSlider from "../components/CustomSlider"

const Container = styled.div`
padding: 0 1rem; 
max-width: 1440px;
margin: 0 auto;
margin-top: 2rem;
@media(min-width: 1280px) {
  padding: 0 2rem; 
}
`

const Title = styled.h2`
  font-style: 1.25rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 2rem;
`

const EinsZweiundDrei = ({ data, props }) => {
  const content = data.allWpPage.nodes[0]

  return (
    <Layout>
      <SEO title={content.title} />
      <Container>
        {content.pageTypeStuehle.stuehle.map(i =>
          <>
            <Title>{i.name}</Title>
            <CustomSlider
              slideData={i.types}
            />
          </>
        )}
        <CustomSlider />
      </Container>
    </Layout>
  )
}

export default EinsZweiundDrei

export const query = graphql`
query {
  allWpPage(filter: {title: {eq: "Stühle"}}) {
    nodes {
      pageTypeStuehle {
        stuehle {
          name
          types {
            type
            price
            link {
              ... on WpStuhl {
                id
                slug
              }
            }
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
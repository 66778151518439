import * as React from "react"

function SlideArrowLeft(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" {...props}>
      <path d="M106.22 12.06a6 6 0 013.69 10.74L46.57 72l63.34 49.2a6 6 0 11-7.36 9.47L34.72 78l-.21-.17a7.55 7.55 0 010-11.64l.21-.17 67.83-52.68a6 6 0 013.67-1.28z" />
    </svg>
  )
}

export default SlideArrowLeft
